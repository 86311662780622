<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  line-height: 1.7rem
  font-size: 12px
  .link
.container-inactive-top
  position: relative
  top: -6rem
  height: 6rem
  background-color: rgba(248,249,250,.7)
.required
  color: tomato
// .container-modal-backdrop
//   // background-color: rgba(248,249,250,.5)
//   background-color: rgba(0,0,0,.2)
//   position: fixed
//   top: 0px
//   left: 0px
//   width: 100%
//   height: 100%
//   z-index: 2000
// .container-modal
//   position: fixed
//   z-index: 2001
//   top: 3rem
//   width: 760px
//   left: calc(50% - 760px/2)
//   .close
//     font-size: 24px
//   .body
//   .text-description
//     color: darken(#f8f9fa, 50%)
//     font-size: 12px
//     font-weight: 600
.container-table
  font-size: 12px
  // overflow: scroll
  // position: relative
  // height: calc(100% - 300px)
  // height: 300px
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
    // font-weight: 800
    color: #333 !important
  .link-view-out
    text-decoration: none
    &.router-link-active
      background-color: #f0f0f0
    .link-view
      color: #007bff
      border-bottom: solid 0.05em #007bff
      font-weight: bold
      transition: color linear 100ms, border linear 100ms
    &:hover
      .link-view
        color: #333
        border-color: lighten(#333, 30%)
        text-decoration: none
    &:visited
      .link-view
        color: #555
        border-bottom: solid 0.05em lighten(#555, 30%)
      &:hover
        .link-view
          color: #333
  // .link-view
  //   // text-decoration: underline
  //   color: #007bff
  //   border-bottom: solid 0.05em #007bff
  //   font-weight: bold
  //   transition: color linear 100ms, border linear 100ms
  //   &:hover
  //     color: #333
  //     border-color: lighten(#333, 30%)
  //     text-decoration: none
  //   &:visited
  //     color: #555
  //     border-bottom: solid 0.05em lighten(#555, 30%)
  //     &:hover
  //       color: #333
  tbody
    tr
      td
        color: #555
</style>
<template lang="pug">
div
  .bg-white.shadow-sm.mb-2
    .p-3
      .mb-4
        router-link.sub-link.mr-3(:to='`/property/${property_id}/message/all`')
          strong 내역조회
        router-link.sub-link.mr-3(:to='`/property/${property_id}/message/reply`')
          strong 답변도착

      h5.title.mb-4 메시지 발송내역
      .mb-2
        button.btn.mr-2.font-weight-bold.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "all"; load();' :class='[current_filter == "all" ? "btn-primary" : "btn-light"]') 전체
        button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "email"; load();' :class='[current_filter == "email" ? "btn-primary" : "btn-light"]') 이메일
        button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "sms"; load();' :class='[current_filter == "sms" ? "btn-primary" : "btn-light"]') 문자
        button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "kakao"; load();' :class='[current_filter == "kakao" ? "btn-primary" : "btn-light"]') 카카오톡
      .pb-2
        small {{rows.length}}건
          span.text-muted  (총 {{count_all}}건)
      //- .container-filter.p-2
        //- a.link.btn.btn-default.text-secondary(href='#') 전체 (0)
        a.link.btn.btn-light.text-primary.mr-1(href='#') 최신순
        a.link.btn.btn-default.text-secondary.mr-1(href='#') 주문대기
        //- a.link.ml-3.btn.text-secondary(href='#') 새 필터 만들기
        button.btn.btn-default.link-search.text-primary.ml-2(type='button') 새 필터 만들기
      //- .container-search.p-2.shadow-sm(v-if='filter_string == `all`')
        form.form-inline.d-inline
          input.form-control.form-control-sm.rounded.bg-light(type='search' style='width: 300px')
          button.btn.btn-default.link-search.text-primary.ml-2(type='button') 검색하기
          button.btn.btn-default.link-search.text-primary.ml-2(type='button') 정렬


        //- router-link.link.ml-3.btn.btn-default.text-primary.float-right(:to='`/property/${property_id}/customer/${document_id}/start-message`') 메시지 보내기
        //- router-link.link.ml-3.btn.btn-default.text-primary.float-right(:to='`/property/${property_id}/customer/${document_id}/start-from-scratch`') 새 고객 추가하기
      .container-table.async(:class='{done:done}' v-if='filter_string == `all`' style='min-height: 500px')
        table.table
          thead
            tr
              th 제목
              th 일시
              //- th 분류
              //- th 통계
          tbody
            tr(v-for='(row, $index) in rows')
              td
                router-link.d-block.link-view-out(:to='`/property/${property_id}/message/${row.id}/audience`')
                  span.link-view {{row.title || '-'}}
              td {{row.created_at | datetime}}
              //- td {{row.status_text}}
              //- td {{row.target_text}}
              //- td {{row.stat}}

        div(style='padding-top: 50vh')
  //- h1 {{document.config.cols.length}}
  //- .container-inactive-top(v-if='done && rows.length == 0')

  //- router-view(@updated='row_updated')

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'filter_string'],
  components: {

  },
  computed: {

  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document_id'() {
    //   this.load()
    // },
    'message_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      // adding: false,
      // document: {},
      rows: [],
      current_filter: 'all',
      count_all: 0,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    this.load()
    // if (this.$store.state.documents) {
    //   this.load()
    // }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages`, {

        })
        if (r?.data?.message != 'ok') throw new Error('발송내역 가져오기 실패')
        this.count_all = r.data.rows.length
        this.rows = r.data.rows.map(e => {
          return e
        }).filter(e => {
          if (this.current_filter == 'all') return true
          if (this.current_filter == 'email') {
            return e.form_json.has_email == 'Y'
          }
          if (this.current_filter == 'sms') {
            return e.form_json.has_sms == 'Y'
          }
        })

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    row_updated() {
      console.log('reload from row')
      this.load()
    }
  },
}
</script>
